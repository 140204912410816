import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import "./index.css";
import "transition-style";
import "transition-style/transition.circles.min.css";
import Select from "react-select";

function AdminComponent() {
  const fileInputRef = useRef(null);
  const [Userdata, setUserData] = useState({
    userInput: "",
    pdf_file: null,
    selected_pdf: [],
    pdf_file_name: "",
  });
  const [uploadMessage, setUploadMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [pdflist, setPdfList] = useState();
  const [selectedPdfFiles, setSelectedPdfFiles] = useState([]);

  const handleFileChange = (e) => {
    const { name, type, value } = e.target;
    console.log("value", value);
    const selectedFile = type === "file" ? e.target.files[0] : value;

    if (type === "file" && selectedFile) {
      // Check if the selected file is a PDF
      if (selectedFile.type === "application/pdf") {
        // Set the selected PDF file in the component state
        setUserData((prevState) => ({
          ...prevState,
          [name]: selectedFile,
          pdf_file_name: selectedFile.name,
        }));
      } else {
        // Display an error message for non-PDF files
        // console.error('Invalid file type. Please select a PDF file.');
        setErrorMessage(true);
        setTimeout(() => {
          setErrorMessage(false);
        }, 3000);
        // Optionally, you can reset the input field here
        e.target.value = ""; // Reset the file input value to clear the selection
      }
    } else {
      // For non-file inputs, directly update the state with the value
      const newValue = e.target.value;
      setUserData((prevState) => ({
        ...prevState,
        [name]: newValue,
      }));
    }
  };

  const handleFileUpload = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("file", Userdata.pdf_file);

    try {
      const response = await axios.post(
        "http://3.81.255.20:3000/upload/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        setUserData((prevState) => ({
          ...prevState,
          userInput: "",
          pdf_file: null,
          pdf_file_name: "",
        }));
        if (fileInputRef?.current) {
          fileInputRef.current.value = "";
        }
        fetchData();
        setUploadMessage("Uploaded successfully ...");
        setTimeout(() => {
          setUploadMessage("");
        }, 3000);
        return;
      } else {
        setErrorMessage("Only PDF files are allowed...");
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    } catch (error) {
      setErrorMessage("Only PDF files are allowed...");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      console.error("Error uploading file:", error);
    }
  };

  const handleSelectFile = (selectedFiles) => {
    const tempSelectedFiles = [];
    selectedFiles.forEach((file) => {
      tempSelectedFiles.push(file.value);
    });
    setSelectedPdfFiles(selectedFiles);
    setUserData((prevState) => ({
      ...prevState,
      selected_pdf: tempSelectedFiles,
    }));
  };

  const fetchData = async () => {
    try {
      const response = await axios.get("http://3.81.255.20:3000/");
      // console.log(response.data.all_pdf_files);
      const pdfList = [];
      response.data.all_pdf_files.forEach((pdf) => {
        pdfList.push({ value: pdf, label: pdf });
      });
      setPdfList(pdfList);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleToGetSelectedDocs = async () => {
    try {
      const response = await axios.get("http://3.81.255.20:3000/selected-documents/");
      let selected_pdf_files =[]
      response?.data?.selected_pdf.map((file) => {
        selected_pdf_files.push({ value: file, label: file });
      })
      setSelectedPdfFiles(selected_pdf_files);
      setUserData((prevState) => ({
        ...prevState,
        selected_pdf: response?.data?.selected_pdf || [],
      }));
    } catch (error) {
      console.error("Error while get selected docs:", error);
    }
  };

  const handleToSelectedDocuments = async () => {
    try {
      const response = await axios.post("http://3.81.255.20:3000/select-pdf/", {
        selected_pdf: Userdata.selected_pdf,
      });
      if (response.status === 200) {
        setUploadMessage("Documents selected successfully ...");
        setTimeout(() => {
          setUploadMessage("");
        }, 3000);
      } else {
        setErrorMessage("Error while get selected docs ...");
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    } catch (error) {
      console.error("Error while get selected docs:", error);
    }
  };

  useEffect(() => {
    handleToGetSelectedDocs();
    fetchData();
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#0e2c23",
        color: "white",
        height: "100vh",
        width: "100vw",
      }}
    >
      {/* Popup for successful upload */}
      <div
        className="popup"
        id="popupMessage"
        style={{ display: uploadMessage ? "block" : "none" }}
      >
       {uploadMessage}
      </div>

      {/* Popup for error message */}
      <div
        className="Errorpopup"
        id="popupError"
        style={{ display: errorMessage ? "block" : "none" }}
      >
        {errorMessage}
      </div>

      <div className="Firstcontainer">
        <div className="column">
          <div className="main-container">
            <div id="chat-container">
              <div id="chat-input">
                <form onSubmit={handleFileUpload} style={{ margin: "unset" }}>
                  <div
                    style={{
                      width: "98.5%",
                      margin: "0.2%",
                      paddingTop: "5px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Select
                      value={selectedPdfFiles}
                      onChange={(e) => handleSelectFile(e)}
                      options={pdflist}
                      isMulti
                      placeholder="Select Files"
                      className="basic-multi-select"
                    />
                    <button
                      className="custom-button"
                      onClick={handleToSelectedDocuments}
                      type="button"
                    >
                      Submit
                    </button>
                  </div>
                  <div>
                    <input
                      style={{ color: "whitesmoke" }}
                      type="file"
                      name="pdf_file"
                      id="pdfFile"
                      accept=".pdf"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                    />

                    <button
                      className="custom-button"
                      onClick={handleFileUpload}
                      type="button"
                      disabled={!Userdata.pdf_file}
                      style={{
                        cursor: !Userdata.pdf_file ? "not-allowed" : "pointer",
                        opacity: !Userdata.pdf_file ? "0.5" : "1",
                      }}
                    >
                      Upload
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminComponent;
